<!-- Title -->
<h3 class="ui-portal-title">Subscriptions</h3>

<div class="card">
  @if (model.subscription.state) {
    <div>
      <h4>Unsubscribe from everything</h4>
      <p class="desc">
        If you no longer wish to receive and retain all of your subscriptions, please click the "unsubscribe" option.
        Please be aware that this action will result in the deletion of all your subscriptions settings.
      </p>
    </div>
  } @else {
    <div>
      <h4><div class="ui-icon-info icon-warning"></div>All your notifications are muted.</h4>
      <p class="desc">
        You are no longer receiving updates for your subscriptions. Please click the "Subscribe" option to enable them again.
      </p>
    </div>
  }
  <div>
    <button class="btn-primary" (click)="toggleAll()">{{model.subscription.state ? 'Unsubscribe' : 'Subscribe'}}</button>
  </div>
</div>
