import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit} from '@angular/core';
import {ActivatedRoute, ResolveFn} from '@angular/router';
import {IUserSubscriptions} from '../../services/api.types';
import {APIService} from '../../services/api.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {ProgressService} from '../../../oex-ui-kit/services/progress.service';
import {ModalService} from '../../services/modal.service';


export const resolveUserSubscriptions: ResolveFn<IUserSubscriptions> = async () => {
  const api = inject(APIService);
  const res = await api.getUserSubscriptions();
  return res;
}

@Component({
    selector: 'st-subscriptions-page',
    imports: [],
    templateUrl: './subscriptions-page.component.html',
    styleUrl: './subscriptions-page.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubscriptionsPageComponent implements OnInit{
  private route = inject(ActivatedRoute);
  private ps = inject(ProgressService);
  private modal = inject(ModalService);
  private cdr = inject(ChangeDetectorRef);
  private api = inject(APIService);

  model!: IUserSubscriptions;
  private data$ = this.route.data.pipe(takeUntilDestroyed());

  ngOnInit() {
    this.data$.subscribe(d => {
      this.model = d.model;
    });
  }

  async toggleAll() {
    this.ps.show();
    try {
      await this.api.toggleUserSubscriptions();
      this.model.subscription.state = !this.model.subscription.state;
      this.cdr.detectChanges();
    } catch (e) {
      this.modal.showError(e);
    } finally {
      this.ps.hide();
    }
  }
}
